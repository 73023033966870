import { translate } from 'react-internationalization';

export function getTypeApproval() {

    let list = [

        { text: translate('enums.customerTypeApproval.manual'), index: 0 },
        { text: translate('enums.customerTypeApproval.automatic'), index: 1 }

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}