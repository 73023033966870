import React from 'react';
import Select from 'react-select';
import { translate } from 'react-internationalization';
import $ from 'jquery';

export default class RCSelect extends React.Component {

    constructor(props) {

        super(props);

    }

    componentDidMount() {

        $('#' + this.props.id).parent().css({ 'position': 'relative' });
        $('.css-bgvzuu-indicatorSeparator, .css-622gbt-indicatorSeparator').removeClass('css-bgvzuu-indicatorSeparator css-622gbt-indicatorSeparator').addClass('caret');

    }

    componentDidUpdate() {

        $('#' + this.props.id).parent().css({ 'position': 'relative' });
        $('.css-bgvzuu-indicatorSeparator, .css-622gbt-indicatorSeparator').removeClass('css-bgvzuu-indicatorSeparator css-622gbt-indicatorSeparator').addClass('caret');

    }


    getOptions() {

        let options = Object.assign([], this.props.options ? this.props.options : []);
        if (this.props.hasEmptyOption) options.unshift({ [this.props.optionLabel]: translate('forms.emptyOptionForComboBox') });

        return options;

    }

    setValue(value) {

        let event = {

            target: {

                id: this.props.id,
                name: this.props.name,
                className: this.props.className,
                type: this.props.type,
                dataset: { index: this.props.dataIndex }

            }

        };

        if (!this.props.isMulti) {

            event.target.value = value[this.props.optionValue];

        } else {

            event.target.value = value.map(x => { return x[this.props.optionValue]; });

        }

        this.props.onChange(event);

    }

    getValue() {

        if (!this.props.options || this.props.options.length <= 0 || this.props.value === null || this.props.value === undefined || this.props.value === 'default') {

            return null;

        } else if (!this.props.isMulti) {

            let value = this.props.isInt ? parseInt(this.props.value) : this.props.value;
            return this.props.options.find(x => { return x[this.props.optionValue] === value; });

        } else {

            if (!this.props.isMulti) {

                let value = this.props.isInt ? parseInt(this.props.value) : this.props.value;
                return this.props.options.find(x => { return x[this.props.optionValue] === value; });

            } else {

                let values = this.props.value.map(x => { return this.props.isInt ? parseInt(x) : x; });
                return this.props.options.filter(x => { return values.includes(x[this.props.optionValue]); });

            }
        }

    }


    render() {

        return (

            <div id={this.props.id}>
                {
                    this.props.isMulti
                    &&
                    <div className="div-combo-buttons">
                        <a role="button" onClick={() => { this.setValue(this.props.options); }}>
                            {translate('forms.comboSelectAll')}
                        </a>

                        <a role="button" onClick={() => { this.setValue([]); }}>
                            {translate('forms.comboUnselectAll')}
                        </a>
                    </div>
                }

                <Select
                    closeMenuOnSelect={!this.props.isMulti}
                    getOptionLabel={option => option[this.props.optionLabel]}
                    getOptionValue={option => option[this.props.optionValue]}
                    isDisabled={this.props.isDisabled}
                    isMulti={this.props.isMulti}
                    options={this.getOptions()}
                    placeholder={this.props.placeholder}
                    onChange={value => { this.setValue(value); }}
                    value={this.getValue()}
                    styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                />
            </div>

        );

    }
}