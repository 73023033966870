import React from 'react';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';

export default class RCDatePicker extends React.Component {

	constructor(props) {

		super(props);

    }

	handleChange(component, value) {

		component = document.getElementById(component);
        component.value = value ? value.format('YYYY-MM-DD') : '';

		this.props.onChange({ target: component });

    }

    disabledDate(date) {

        if (!this.props.disablePast || !date) return false;

        const current = window.Moment(this.props.value);
        const today = window.Moment();

        return !date.isSame(current, 'day') && date.isBefore(today, 'day');

    }


    render() {

        return (
			<DatePicker		
                animation="slide-up"
                calendar={<Calendar disabledDate={(date) => { return this.disabledDate(date); }} style={{ zIndex: 1001 }} />}
                disabled={this.props.disabled}
                value={this.props.value ? window.Moment(this.props.value, 'YYYY-MM-DD') : window.Moment()}
                onChange={(value) => { this.handleChange(this.props.id, value) }}
                style={this.props.style || { zIndex: 1001 }}>
				{
					() => {

						return (
							<span>
                                <input
                                    id={this.props.id}
                                    name={this.props.name}
                                    className="form-control"
                                    disabled={this.props.disabled}
                                    placeholder={window.Moment().format('DD-MM-YYYY')}
                                    readOnly
                                    title={this.props.title}
                                    type="text"
                                    value={this.props.value ? window.Moment(this.props.value, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                                    data-index={this.props.index}
								/>
							</span>
						);
					}
				}
			</DatePicker>
        );

    }

}