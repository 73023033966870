import { translate } from 'react-internationalization';

export function getTypes() {

    let list = [

        { text: translate('enums.userType.administrator'), index: 0 },
        { text: translate('enums.userType.customer'), index: 1 },
        { text: translate('enums.userType.supplier'), index: 2 },
        { text: translate('enums.userType.bank'), index: 3 },
        { text: translate('enums.userType.operator'), index: 4 }

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getDocumentType() {

    let list = [

        { text: translate('enums.userDocumentType.cnpj'), index: 0 },
        { text: translate('enums.userDocumentType.cpf'), index: 1 }

    ];

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}

export function getRoles(type) {

    let list = [

        { id: 1, name: 'Account-List', type: [] },
        { id: 2, name: 'Account-Get', type: [] },
        { id: 3, name: 'Account-Delete', type: [] },
        { id: 4, name: 'Account-Save', type: [] },
        { id: 5, name: 'Bank-List', type: [3, 4] },
        { id: 6, name: 'Bank-Get', type: [3, 4] },
        { id: 7, name: 'Bank-Delete', type: [] },
        { id: 8, name: 'Bank-Save', type: [] },
        { id: 9, name: 'Customer-List', type: [1, 2, 3, 4] },
        { id: 10, name: 'Customer-Get', type: [1, 2, 3, 4] },
        { id: 11, name: 'Customer-Delete', type: [] },
        { id: 12, name: 'Customer-Save', type: [4] },
        { id: 13, name: 'Document-List', type: [1, 2, 3, 4] },
        { id: 14, name: 'Document-Get', type: [1, 2, 3, 4] },
        { id: 15, name: 'Document-Delete', type: [] },
        { id: 16, name: 'Document-Save', type: [] },
        { id: 17, name: 'Supplier-List', type: [1, 2, 3, 4] },
        { id: 18, name: 'Supplier-Get', type: [1, 2, 3, 4] },
        { id: 19, name: 'Supplier-Delete', type: [] },
        { id: 20, name: 'Supplier-Save', type: [] },
        { id: 21, name: 'Transaction-List', type: [1, 2, 3, 4] },
        { id: 22, name: 'Transaction-Get', type: [1, 2, 3, 4] },
        { id: 23, name: 'Transaction-Delete', type: [] },
        { id: 24, name: 'Transaction-Save', type: [2, 4] },
        { id: 25, name: 'Invoice-Import', type: [1] },
        { id: 26, name: 'Invoice-List', type: [1, 2, 3, 4] },
        { id: 27, name: 'Log-List', type: [] },
        { id: 28, name: 'Log-Get', type: [] },
        { id: 29, name: 'Log-Delete', type: [] },
        { id: 30, name: 'Supplier-UpdateRate', type: [4] },
        { id: 31, name: 'Transaction-Process', type: [4] },
        { id: 32, name: 'CNAB-Get', type: [1, 3, 4] },
        { id: 33, name: 'CNAB-Save', type: [1, 3, 4] }

    ];

    if (type !== null) {

        list = list.filter(function (item) {
            return item.type.find(x => { return x === type; });
        })
    }

    list = list.sort(function (a, b) { return (a.text < b.text) ? -1 : (a.text > b.text) ? 1 : 0; });

    return list;
}