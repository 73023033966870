
export function getBankNumber() {

    let list = [

        { text: '104 - CAIXA ECONOMICA FEDERAL', index: '104' },
        { text: '341 - ITAÚ UNIBANCO S.A.', index: '341' },
        { text: '001 - BCO DO BRASIL S.A.', index: '001' },
        { text: '237 - BCO BRADESCO S.A.', index: '237' },
        { text: '033 - BCO SANTANDER (BRASIL) S.A.', index: '033' },
        { text: '260 - NU PAGAMENTOS S.A.', index: '260' },
        { text: '140 - EASYNVEST - TÍTULO CV SA', index: '140' },
        { text: '102 - XP INVESTIMENTOS CCTVM S/A', index: '102' },
        { text: '746 - BCO MODAL S.A.', index: '746' },
        { text: '003 - BCO DA AMAZONIA S.A.', index: '003' },
        { text: '004 - BCO DO NORDESTE DO BRASIL S.A.', index: '004' },
        { text: '007 - BNDES', index: '007' },
        { text: '010 - CREDICOAMO', index: '010' },
        { text: '011 - C.SUISSE HEDGING-GRIFFO CV S/A', index: '011' },
        { text: '012 - BANCO INBURSA', index: '012' },
        { text: '014 - STATE STREET BR S.A. BCO COMERCIAL', index: '014' },
        { text: '015 - UBS BRASIL CCTVM S.A.', index: '015' },
        { text: '016 - SICOOB CREDITRAN', index: '016' },
        { text: '017 - BNY MELLON BCO S.A.', index: '017' },
        { text: '018 - BCO TRICURY S.A.', index: '018' },
        { text: '021 - BCO BANESTES S.A.', index: '021' },
        { text: '024 - BCO BANDEPE S.A.', index: '024' },
        { text: '025 - BCO ALFA S.A.', index: '025' },
        { text: '029 - BANCO ITAÚ CONSIGNADO S.A.', index: '029' },
        { text: '036 - BCO BBI S.A.', index: '036' },
        { text: '037 - BCO DO EST. DO PA S.A.', index: '037' },
        { text: '040 - BCO CARGILL S.A.', index: '040' },
        { text: '041 - BCO DO ESTADO DO RS S.A.', index: '041' },
        { text: '047 - BCO DO EST. DE SE S.A.', index: '047' },
        { text: '060 - CONFIDENCE CC S.A.', index: '060' },
        { text: '062 - HIPERCARD BM S.A.', index: '062' },
        { text: '063 - BANCO BRADESCARD', index: '063' },
        { text: '064 - GOLDMAN SACHS DO BRASIL BM S.A', index: '064' },
        { text: '065 - BCO ANDBANK S.A.', index: '065' },
        { text: '066 - BCO MORGAN STANLEY S.A.', index: '066' },
        { text: '069 - BCO CREFISA S.A.', index: '069' },
        { text: '070 - BRB - BCO DE BRASILIA S.A.', index: '070' },
        { text: '074 - BCO. J.SAFRA S.A.', index: '074' },
        { text: '075 - BCO ABN AMRO S.A.', index: '075' },
        { text: '076 - BCO KDB BRASIL S.A.', index: '076' },
        { text: '077 - BANCO INTER', index: '077' },
        { text: '078 - HAITONG BI DO BRASIL S.A.', index: '078' },
        { text: '079 - BCO ORIGINAL DO AGRO S/A', index: '079' },
        { text: '080 - B&T CC LTDA.', index: '080' },
        { text: '081 - BANCOSEGURO S.A.', index: '081' },
        { text: '082 - BANCO TOPÁZIO S.A.', index: '082' },
        { text: '083 - BCO DA CHINA BRASIL S.A.', index: '083' },
        { text: '084 - UNIPRIME NORTE DO PARANÁ - CC', index: '084' },
        { text: '085 - COOP CENTRAL AILOS', index: '085' },
        { text: '089 - CCR REG MOGIANA', index: '089' },
        { text: '091 - CCCM UNICRED CENTRAL RS', index: '091' },
        { text: '092 - BRK S.A. CFI', index: '092' },
        { text: '093 - POLOCRED SCMEPP LTDA.', index: '093' },
        { text: '094 - BANCO FINAXIS', index: '094' },
        { text: '095 - TRAVELEX BANCO DE CÂMBIO S.A.', index: '095' },
        { text: '096 - BCO B3 S.A.', index: '096' },
        { text: '097 - CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.', index: '097' },
        { text: '098 - CREDIALIANÇA CCR', index: '098' },
        { text: '099 - UNIPRIME CENTRAL CCC LTDA.', index: '099' },
        { text: '100 - PLANNER CV S.A.', index: '100' },
        { text: '101 - RENASCENCA DTVM LTDA', index: '101' },
        { text: '105 - LECCA CFI S.A.', index: '105' },
        { text: '107 - BCO BOCOM BBM S.A.', index: '107' },
        { text: '108 - PORTOCRED S.A. - CFI', index: '108' },
        { text: '111 - OLIVEIRA TRUST DTVM S.A.', index: '111' },
        { text: '113 - MAGLIANO S.A. CCVM', index: '113' },
        { text: '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO', index: '114' },
        { text: '117 - ADVANCED CC LTDA', index: '117' },
        { text: '118 - STANDARD CHARTERED BI S.A.', index: '118' },
        { text: '119 - BCO WESTERN UNION', index: '119' },
        { text: '120 - BCO RODOBENS S.A.', index: '120' },
        { text: '121 - BCO AGIBANK S.A.', index: '121' },
        { text: '122 - BCO BRADESCO BERJ S.A.', index: '122' },
        { text: '124 - BCO WOORI BANK DO BRASIL S.A.', index: '124' },
        { text: '125 - PLURAL BCO BM', index: '125' },
        { text: '126 - BR PARTNERS BI', index: '126' },
        { text: '127 - CODEPE CVC S.A.', index: '127' },
        { text: '128 - MS BANK S.A. BCO DE CÂMBIO', index: '128' },
        { text: '129 - UBS BRASIL BI S.A.', index: '129' },
        { text: '130 - CARUANA SCFI', index: '130' },
        { text: '131 - TULLETT PREBON BRASIL CVC LTDA', index: '131' },
        { text: '132 - ICBC DO BRASIL BM S.A.', index: '132' },
        { text: '133 - CRESOL CONFEDERAÇÃO', index: '133' },
        { text: '134 - BGC LIQUIDEZ DTVM LTDA', index: '134' },
        { text: '136 - UNICRED', index: '136' },
        { text: '138 - GET MONEY CC LTDA', index: '138' },
        { text: '139 - INTESA SANPAOLO BRASIL S.A. BM', index: '139' },
        { text: '142 - BROKER BRASIL CC LTDA.', index: '142' },
        { text: '143 - TREVISO CC S.A.', index: '143' },
        { text: '144 - BEXS BCO DE CAMBIO S.A.', index: '144' },
        { text: '145 - LEVYCAM CCV LTDA', index: '145' },
        { text: '146 - GUITTA CC LTDA', index: '146' },
        { text: '149 - FACTA S.A. CFI', index: '149' },
        { text: '157 - ICAP DO BRASIL CTVM LTDA.', index: '157' },
        { text: '159 - CASA CREDITO S.A. SCM', index: '159' },
        { text: '163 - COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO', index: '163' },
        { text: '169 - BCO OLÉ BONSUCESSO CONSIGNADO S.A.', index: '169' },
        { text: '173 - BRL TRUST DTVM SA', index: '173' },
        { text: '174 - PERNAMBUCANAS FINANC S.A. CFI', index: '174' },
        { text: '177 - GUIDE', index: '177' },
        { text: '180 - CM CAPITAL MARKETS CCTVM LTDA', index: '180' },
        { text: '182 - DACASA FINANCEIRA S/A - SCFI', index: '182' },
        { text: '183 - SOCRED SA - SCMEPP', index: '183' },
        { text: '184 - BCO ITAÚ BBA S.A.', index: '184' },
        { text: '188 - ATIVA S.A. INVESTIMENTOS CCTVM', index: '188' },
        { text: '189 - HS FINANCEIRA', index: '189' },
        { text: '190 - SERVICOOP', index: '190' },
        { text: '191 - NOVA FUTURA CTVM LTDA.', index: '191' },
        { text: '194 - PARMETAL DTVM LTDA', index: '194' },
        { text: '196 - FAIR CC S.A.', index: '196' },
        { text: '197 - STONE PAGAMENTOS S.A.', index: '197' },
        { text: '208 - BANCO BTG PACTUAL S.A.', index: '208' },
        { text: '212 - BANCO ORIGINAL', index: '212' },
        { text: '213 - BCO ARBI S.A.', index: '213' },
        { text: '217 - BANCO JOHN DEERE S.A.', index: '217' },
        { text: '218 - BCO BS2 S.A.', index: '218' },
        { text: '222 - BCO CRÉDIT AGRICOLE BR S.A.', index: '222' },
        { text: '224 - BCO FIBRA S.A.', index: '224' },
        { text: '233 - BANCO CIFRA', index: '233' },
        { text: '241 - BCO CLASSICO S.A.', index: '241' },
        { text: '243 - BCO MÁXIMA S.A.', index: '243' },
        { text: '246 - BCO ABC BRASIL S.A.', index: '246' },
        { text: '249 - BANCO INVESTCRED UNIBANCO S.A.', index: '249' },
        { text: '250 - BCV', index: '250' },
        { text: '253 - BEXS CC S.A.', index: '253' },
        { text: '254 - PARANA BCO S.A.', index: '254' },
        { text: '265 - BCO FATOR S.A.', index: '265' },
        { text: '266 - BCO CEDULA S.A.', index: '266' },
        { text: '268 - BARI CIA HIPOTECÁRIA', index: '268' },
        { text: '269 - HSBC BANCO DE INVESTIMENTO', index: '269' },
        { text: '270 - SAGITUR CC LTDA', index: '270' },
        { text: '271 - IB CCTVM S.A.', index: '271' },
        { text: '272 - AGK CC S.A.', index: '272' },
        { text: '273 - CCR DE SÃO MIGUEL DO OESTE', index: '273' },
        { text: '274 - MONEY PLUS SCMEPP LTDA', index: '274' },
        { text: '276 - SENFF S.A. - CFI', index: '276' },
        { text: '278 - GENIAL INVESTIMENTOS CVM S.A.', index: '278' },
        { text: '279 - CCR DE PRIMAVERA DO LESTE', index: '279' },
        { text: '280 - AVISTA S.A. CFI', index: '280' },
        { text: '281 - CCR COOPAVEL', index: '281' },
        { text: '283 - RB CAPITAL INVESTIMENTOS DTVM LTDA.', index: '283' },
        { text: '285 - FRENTE CC LTDA.', index: '285' },
        { text: '286 - CCR DE OURO', index: '286' },
        { text: '288 - CAROL DTVM LTDA.', index: '288' },
        { text: '289 - DECYSEO CC LTDA.', index: '289' },
        { text: '290 - PAGSEGURO', index: '290' },
        { text: '292 - BS2 DTVM S.A.', index: '292' },
        { text: '293 - LASTRO RDV DTVM LTDA', index: '293' },
        { text: '296 - VISION S.A. CC', index: '296' },
        { text: '298 - VIPS CC LTDA.', index: '298' },
        { text: '299 - SOROCRED CFI S.A.', index: '299' },
        { text: '300 - BCO LA NACION ARGENTINA', index: '300' },
        { text: '301 - BPP IP S.A.', index: '301' },
        { text: '306 - PORTOPAR DTVM LTDA', index: '306' },
        { text: '307 - TERRA INVESTIMENTOS DTVM', index: '307' },
        { text: '309 - CAMBIONET CC LTDA', index: '309' },
        { text: '310 - VORTX DTVM LTDA.', index: '310' },
        { text: '315 - PI DTVM S.A.', index: '315' },
        { text: '318 - BCO BMG S.A.', index: '318' },
        { text: '319 - OM DTVM LTDA', index: '319' },
        { text: '320 - BCO CCB BRASIL S.A.', index: '320' },
        { text: '321 - CREFAZ SCMEPP LTDA', index: '321' },
        { text: '322 - CCR DE ABELARDO LUZ', index: '322' },
        { text: '323 - MERCADO PAGO', index: '323' },
        { text: '325 - ÓRAMA DTVM S.A.', index: '325' },
        { text: '326 - PARATI - CFI S.A.', index: '326' },
        { text: '329 - QI SCD S.A.', index: '329' },
        { text: '330 - BANCO BARI S.A.', index: '330' },
        { text: '331 - FRAM CAPITAL DTVM S.A.', index: '331' },
        { text: '332 - ACESSO', index: '332' },
        { text: '335 - BANCO DIGIO', index: '335' },
        { text: '336 - BCO C6 S.A.', index: '336' },
        { text: '340 - SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.', index: '340' },
        { text: '342 - CREDITAS SCD', index: '342' },
        { text: '343 - FFA SCMEPP LTDA.', index: '343' },
        { text: '348 - BCO XP S.A.', index: '348' },
        { text: '349 - AMAGGI S.A. CFI', index: '349' },
        { text: '352 - TORO CTVM LTDA', index: '352' },
        { text: '354 - NECTON INVESTIMENTOS S.A CVM', index: '354' },
        { text: '355 - ÓTIMO SCD S.A.', index: '355' },
        { text: '366 - BCO SOCIETE GENERALE BRASIL', index: '366' },
        { text: '370 - BCO MIZUHO S.A.', index: '370' },
        { text: '376 - BCO J.P. MORGAN S.A.', index: '376' },
        { text: '389 - BCO MERCANTIL DO BRASIL S.A.', index: '389' },
        { text: '394 - BCO BRADESCO FINANC. S.A.', index: '394' },
        { text: '399 - KIRTON BANK', index: '399' },
        { text: '412 - BCO CAPITAL S.A.', index: '412' },
        { text: '422 - BCO SAFRA S.A.', index: '422' },
        { text: '456 - BCO MUFG BRASIL S.A.', index: '456' },
        { text: '464 - BCO SUMITOMO MITSUI BRASIL S.A.', index: '464' },
        { text: '473 - BCO CAIXA GERAL BRASIL S.A.', index: '473' },
        { text: '477 - CITIBANK N.A.', index: '477' },
        { text: '479 - BCO ITAUBANK S.A.', index: '479' },
        { text: '487 - DEUTSCHE BANK S.A.BCO ALEMAO', index: '487' },
        { text: '488 - JPMORGAN CHASE BANK', index: '488' },
        { text: '492 - ING BANK N.V.', index: '492' },
        { text: '495 - BCO LA PROVINCIA B AIRES BCE', index: '495' },
        { text: '505 - BCO CREDIT SUISSE S.A.', index: '505' },
        { text: '545 - SENSO CCVM S.A.', index: '545' },
        { text: '600 - BCO LUSO BRASILEIRO S.A.', index: '600' },
        { text: '604 - BCO INDUSTRIAL DO BRASIL S.A.', index: '604' },
        { text: '610 - BCO VR S.A.', index: '610' },
        { text: '611 - BCO PAULISTA S.A.', index: '611' },
        { text: '612 - BCO GUANABARA S.A.', index: '612' },
        { text: '613 - OMNI BANCO S.A.', index: '613' },
        { text: '623 - BANCO PAN', index: '623' },
        { text: '626 - BCO FICSA S.A.', index: '626' },
        { text: '630 - SMARTBANK', index: '630' },
        { text: '633 - BCO RENDIMENTO S.A.', index: '633' },
        { text: '634 - BCO TRIANGULO S.A.', index: '634' },
        { text: '637 - BCO SOFISA S.A.', index: '637' },
        { text: '643 - BCO PINE S.A.', index: '643' },
        { text: '652 - ITAÚ UNIBANCO HOLDING S.A.', index: '652' },
        { text: '653 - BCO INDUSVAL S.A.', index: '653' },
        { text: '654 - BCO A.J. RENNER S.A.', index: '654' },
        { text: '655 - BCO VOTORANTIM S.A.', index: '655' },
        { text: '707 - BCO DAYCOVAL S.A', index: '707' },
        { text: '712 - BCO OURINVEST S.A.', index: '712' },
        { text: '739 - BCO CETELEM S.A.', index: '739' },
        { text: '741 - BCO RIBEIRAO PRETO S.A.', index: '741' },
        { text: '743 - BANCO SEMEAR', index: '743' },
        { text: '745 - BCO CITIBANK S.A.', index: '745' },
        { text: '747 - BCO RABOBANK INTL BRASIL S.A.', index: '747' },
        { text: '748 - BCO COOPERATIVO SICREDI S.A.', index: '748' },
        { text: '751 - SCOTIABANK BRASIL', index: '751' },
        { text: '752 - BCO BNP PARIBAS BRASIL S A', index: '752' },
        { text: '753 - NOVO BCO CONTINENTAL S.A. - BM', index: '753' },
        { text: '754 - BANCO SISTEMA', index: '754' },
        { text: '755 - BOFA MERRILL LYNCH BM S.A.', index: '755' },
        { text: '756 - BANCOOB', index: '756' },
        { text: '757 - BCO KEB HANA DO BRASIL S.A.', index: '757' }
    ];

    return list;
}