import React, { Component } from 'react';
import * as session from '../components/SessionValidator';

const moment = window.Moment;

class Helper extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

        };
    }
}

export function submitReportDailyLog(reportDailyLog) {

    reportDailyLog.endDate = moment().format();

    fetch(window.$LogServiceURL + '/api/ReportDailyLog/Create', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': session.getToken()
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(reportDailyLog)
    }).then(res => {

        return res.json();

    });
}

export default Helper;
