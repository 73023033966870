import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-internationalization';
import { ToastContainer } from 'react-toastify';
import ReactTable from "react-table";
import RCDatePicker from '../components/datePicker';
import RCSelect from '../components/select';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import * as session from '../components/SessionValidator';

class ReceivablesPage extends React.Component {

    constructor(props) {

        super(props);
        this.setBinds();

        this.state = {

            customers: [],
            search: { search: '', startDate: window.Moment().add(-90, 'days').format('DD-MM-YYYY'), endDate: window.Moment().format('DD-MM-YYYY'), accountNumber: '', dateFilter: 0 }, // dateFilter = 0 - Filter per ReadingDate / Filter per ProcessedDate
        };

    }

    setBinds() {

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }

    async componentDidMount() {

        $('.js-change').on('change', event => { this.handleChange(event); });
    }

    handleChangeStartDate(event) {

        if (!event.target.value) {

            let endDate = window.Moment(this.state.search.endDate);
            event.target.value = endDate.add(-90, 'days').format('MM-DD-YYYY');

        }

        this.handleChange(event);
    }

    handleChangeEndDate(event) {

        if (!event.target.value) {

            let startDate = window.Moment(this.state.search.startDate);
            event.target.value = startDate.add(90, 'days').format('MM-DD-YYYY');

        }

        this.handleChange(event);

    }

    handleChange(event) {

        let keys = event.target.name.split('.'), property = this.state;

        keys.forEach(key => {

            if (property[key] !== null && typeof property[key] === 'object') {

                property = property[key];
                if (Array.isArray(property)) property = property[event.target.dataset.index];

            } else {

                let value;
                if (event.target.type === 'checkbox') { value = event.target.checked; }
                else { value = event.target.value; }

                property[key] = value;

            }

        });

        this.setState(this.state);

    }
    
    render() {

        let items = this.state.fetched ? this.handleSearch() : [];

        return (
            <div>
                <div style={{ display: 'block' }}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="card">
                                <div className="header">
                                    <h2>
                                        Recebíveis
                                        <small>Lista de Recebíveis por Cliente / Fornecedores</small>
                                    </h2>
                                </div>

                                <div className="body">

                                    <div className="m-t-10">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="m-b-5">
                                                    <label htmlFor="cmbCustomer">
                                                        Cliente
                                                    </label>

                                                    <RCSelect
                                                        id="cmbCustomer"
                                                        hasEmptyOption={true}
                                                        isInt={true}
                                                        options={this.state.customers}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        placeholder={translate('bill.billCustomerPlaceHolder')}
                                                        onChange={event => { this.handleChangeCustomer(event); }}
                                                        value={this.state.search.customerId}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="m-b-5">
                                                    <label htmlFor="cmbSite">
                                                        Fornecedor
                                                    </label>

                                                    <div className="preloader pl-size-xs form-preloader" style={{ display: this.state.loadingSites ? 'inline-block' : 'none' }}>
                                                        <div className="spinner-layer">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <RCSelect
                                                        id="cmbSite"
                                                        name="search.siteId"
                                                        hasEmptyOption={true}
                                                        isInt={true}
                                                        options={this.state.sites}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        placeholder={translate('bill.billSitePlaceHolder')}
                                                        onChange={event => { this.handleChange(event); }}
                                                        value={this.state.search.siteId}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <label htmlFor="dtStartDate">
                                                        Data Inicial
                                                    </label>

                                                    <div className="form-line">
                                                        <RCDatePicker id="dtStartDate" name="search.startDate" value={this.state.search.startDate} onChange={this.handleChangeStartDate} />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="input-group">
                                                    <label htmlFor="dtEndDate">
                                                        Data Final
                                                    </label>

                                                    <div className="form-line">
                                                        <RCDatePicker id="dtEndDate" name="search.endDate" value={this.state.search.endDate} onChange={this.handleChangeEndDate} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 m-t-20">
                                                <button className="btn btn-block btn-primary" disabled={this.state.loading} type="button" >
                                                    <i className="material-icons">&#xe8b6;</i>
                                                    <span>{translate('forms.buttonSearch')}</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row m-t-10">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        <i className="material-icons">&#xe8b6;</i>
                                                    </span>

                                                    <div className="form-line">
                                                        <input name="search.search" className="form-control" autoComplete="off" disabled={this.state.loading} placeholder={translate('forms.searchBar')} type="text" value={this.state.search.search} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <ReactTable
                                        columns={[
                                            {
                                                columns: [
                                                    {
                                                        Header: "Cliente / Fornecedor",
                                                        accessor: "name"
                                                    },
                                                    {
                                                        Header: "Qtde NFs",
                                                        accessor: 'countryHeadquarters.name'
                                                    },
                                                    {
                                                        Header: "Valor Total",
                                                        accessor: 'countryHeadquarters.name'
                                                    },
                                                    {
                                                        Header: "Valor Antecipações",
                                                        accessor: 'countryHeadquarters.name'
                                                    },
                                                    {
                                                        Header: "Disponível para Antecipações",
                                                        accessor: 'countryHeadquarters.name'
                                                    },
                                                    {
                                                        Cell: row => (
                                                            <div className="align-center">
                                                                <Link to={"/customertest/" + row.original.id}>
                                                                    <button type="button" className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float">
                                                                        <i className="material-icons">&#xe3c9;</i>
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        ),
                                                        maxWidth: 150
                                                    }
                                                ]
                                            }
                                        ]}
                                        SubComponent={row => {
                                            return row.original.startFiscalYearDate !== null
                                                ?
                                                <div className="m-l-30 m-r-30 m-t-30">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationStartFiscalYearDate')}:
																<b> {window.Moment(row.original.startFiscalYearDate).format('DD-MM-YYYY')}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationEndFiscalYearDate')}:
																<b> {window.Moment(row.original.endFiscalYearDate).format('DD-MM-YYYY')}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationCurrentFinancialYear')}:
																<b> {row.original.currentFinancialYear}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationTargetMethod')}:
																<b> {this.getTargetMethods()[row.original.targetMethod]}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationReportDataMethod')}:
																<b> {this.getReportDataMethods()[row.original.reportDataMethod]}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationDateFormat')}:
																<b> {row.original.dateFormat}</b>
                                                            </p>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <p>
                                                                {translate('customer.customerParametersValidationNumberOfConsecutiveEstimates')}:
																<b> {row.original.numberOfConsecutiveEstimates}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null

                                        }}
                                        data={items}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        loading={this.state.loading}
                                        previousText={translate('forms.previousText')}
                                        nextText={translate('forms.nextText')}
                                        noDataText={translate('forms.noDataText')}
                                        pageText={translate('forms.pageText')}
                                        ofText={translate('forms.ofText')}
                                        rowsText={translate('forms.rowsText')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'none' }}>
                    <div className="preloader pl-size-lg align-center">
                        <div className="spinner-layer">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div>

                            <div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer hideProgressBar />
            </div>
        )
    }
}

export default ReceivablesPage;